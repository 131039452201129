<template>
  <div class="showKnowledge">
    <div class="showKnowledge-title">
      <div class="title-box">
        <span class="title-size"
          ><i class="el-icon-back" @click="linkTo"></i>
          {{ $route.query.knowledgeName }}</span
        >
        <el-select
          v-model="optionsTimeVal"
          placeholder="请选择"
          class="set-input"
          @change="setTime()"
        >
          <el-option
            v-for="item in optionsTime"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div v-loading="loading" class="know-box" element-loading-text="拼命加载中">
      <div class="know-box-left">
        <div class="title">
          <p>题型</p>
          <p>得分率</p>
        </div>
        <div v-for="(v, i) in userInfo.questionTypeList" :key="i">
          <span>{{ v.questionType }}</span>
          <span v-if="v.scoreRate || v.scoreRate == 0">
            {{ v.scoreRate | setNumber }}
            <!-- {{ v.scoreRate * 100 }}% -->
          </span>
        </div>
      </div>
      <div class="know-box-right">
        <p class="title">知识点详情</p>
        <div class="know-box-top">
          <!-- {{ echartsData }} -->
          <!-- 近五年高考考频 0 年级得分率 53.69% 年级考察题数 5 -->
          <p>
            近五年高考考频
            <span
              ><template v-if="userInfo.knowledgeFreq">{{
                userInfo.knowledgeFreq
              }}</template
              ><template v-else>无</template>
            </span>
          </p>
          <p>
            <template v-if="$route.query.urlType == 'GradeLearning'"
              >年级得分率</template
            >
            <template v-if="$route.query.urlType == 'ClassLearning'"
              >班级得分率</template
            >
            <template v-if="$route.query.urlType == 'StudentLearning'"
              >个人得分率</template
            >
            <span v-if="userInfo.scoreRateAvg || userInfo.scoreRateAvg == 0">
              {{ userInfo.scoreRateAvg | setNumber }}
            </span>
          </p>
          <p>
            <template v-if="$route.query.urlType == 'GradeLearning'"
              >年级考察题数</template
            >
            <template v-if="$route.query.urlType == 'ClassLearning'"
              >班级考察题数</template
            >
            <template v-if="$route.query.urlType == 'StudentLearning'"
              >个人考察题数</template
            >
            <span> {{ userInfo.questionCount }}</span>
          </p>
        </div>
        <div v-if="examDateListY.length > 0" class="echarts-box">
          <div id="myChart" :style="{ width: '100%', height: '300px' }"></div>
        </div>
        <p v-if="$route.query.urlType == 'GradeLearning'" class="title">
          掌控情况
        </p>
        <el-tabs
          v-if="!$route.query.studentId"
          v-model="activeName"
          type="border-card"
          class="tabs-list"
        >
          <el-tab-pane label="已掌握" name="first">
            <div class="list-master">
              <div v-for="(v, i) in masterinfoData.masteredList" :key="i">
                {{ v.name }}
                <template v-if="!$route.query.classNum">班</template>
                {{ v.scoreRateAvg | setNumber }}
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="未掌握" name="second">
            <div class="list-master">
              <div v-for="(v, i) in masterinfoData.unmasterList" :key="i">
                {{ v.name }}
                <template v-if="!$route.query.classNum">班</template>
                {{ v.scoreRateAvg | setNumber }}
              </div>
            </div></el-tab-pane
          >
        </el-tabs>
        <p class="title">错题</p>
        <wrongListBox
          v-if="comBoxType"
          :child-obj="childObj"
          :exam-list="examList"
          :question-type-list="questionTypeList"
        ></wrongListBox>
      </div>
    </div>
    <el-dialog
      title="举一反三"
      :visible.sync="dialogVisible"
      width="50%"
      class="model-box"
    >
      <!-- <el-tabs type="border-card">
        <el-tab-pane label="用户管理">用户管理</el-tab-pane>
        <el-tab-pane label="配置管理">配置管理</el-tab-pane>
        <el-tab-pane label="角色管理">角色管理</el-tab-pane>
        <el-tab-pane label="定时任务补偿">定时任务补偿</el-tab-pane>
      </el-tabs> -->
      <template v-for="(v, i) in bsQuestionList">
        <div v-if="i == 0" :key="i">
          <p class="bsTitle">变式题</p>
          <div v-html="v.question"></div>
        </div>
      </template>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span> -->
    </el-dialog>
  </div>
</template>

<script>
const listWrong = [
  { value: 0 },
  { value: 0.1 },
  { value: 0.2 },
  { value: 0.3 },
  { value: 0.4 },
  { value: 0.5 },
  { value: 0.6 },
  { value: 0.7 },
  { value: 0.8 },
  { value: 0.9 },
  { value: 1 },
];
import { optionsTime, examTypeOptions } from "@/core/util/constdata";
import {
  questionInfo,
  questionPage,
  masterinfo,
  paperlistinfo,
  traininglist,
  questionExport,
} from "@/core/api/analy/gradeLearning";
import { fileDownloadByUrl } from "@/core/util/util";
import { trainings } from "@/core/api/exam/examLore";
import { getExamListByTimeRange } from "@/core/api/resource/learningPaper";
import { answer } from "@/core/api/resource/knowledgePointsPaper";
import {
  exampaperplanquestionDel,
  exampaperplanquestion,
} from "@/core/api/resource/knowledgePointsPaper";
import wrongListBox from "@/components/learning/wrongListBox.vue";
export default {
  name: "ShowKnowledge",
  components: {
    wrongListBox,
  },
  filters: {
    setNumber(val) {
      if (!val) {
        return "0.00 %";
      }
      let number = (val * 100).toFixed(2);
      return number + " %";
    },
  },
  data() {
    return {
      //试题篮 start
      planId: 0,
      componentsKey: 1,
      btnKey: 1,
      planQuestionList: [],
      //试题篮 end
      questionTypeList: [],
      examTypeOptions: examTypeOptions,
      loading: true,
      dialogVisible: false,
      optionsTime: optionsTime,
      listWrong: listWrong,
      activeName: "first",
      listVal: {
        // scoreRateMin: 0,
        // scoreRateMax: 0.7,
        examPaperIds: [],
        quesType: "",
      },
      optionsTimeVal: 0,
      //和错题组件对应的字段
      childObj: {},
      comBoxType: false,
      pageList: [],
      userInfo: {},
      page: {
        current: 1,
        size: 10,
      },
      total: 0,
      echartsData: {},
      // name: "111111111",
      examDateListY: [],
      examDateListX: [],
      scoreRateAvg: "",
      masterinfoData: {},
      bsQuestionList: [],
      examList: [],
    };
  },
  created() {
    let pageData = Object.assign(
      this.page,
      this.listVal,
      JSON.parse(JSON.stringify(this.$route.query))
    );
    if (this.$route.query.examPaperIds.length > 0) {
      this.listVal.examPaperIds = this.$route.query.examPaperIds.split(",");
    } else {
      this.listVal.examPaperIds = [];
    }

    let arr = [];
    if (this.listVal.examPaperIds[0] == "") {
      arr = [];
    } else {
      this.listVal.examPaperIds.forEach((index) => {
        arr.push(Number(index));
      });
    }

    this.listVal.examPaperIds = arr;
    this.optionsTimeVal = Number(this.$route.query.timeRange);
    if (this.listVal.quesType == "") {
      delete pageData.quesType;
    }
    delete pageData.knowledgeName;
    delete pageData.urlType;
    this.childObj = pageData;
    this.comBoxType = true;
    this.getMsg(pageData);
    this.getList(pageData);
    this.getChart(pageData);
    this.getMaster(pageData);
    this.getExamListByTimeRange(pageData);
  },
  methods: {
    //试题篮方法 start
    getNewList() {
      // this.getList();
    },
    setPlanid(planId) {
      this.planId = planId;
      // this.getList();
    },
    //已加入试题篮试题列表
    setQuestionList(val) {
      // console.log(val);
      this.planQuestionList = val;
      this.btnKey = new Date().getTime();
    },
    // 移除试题篮
    exampaperplanquestionDel(val, type) {
      let data = {
        examPaperPlanId: this.planId,
        questionId: type ? val.newId : val.questionId,
      };
      if (type == 2) {
        data.questionId = val.id;
      }
      exampaperplanquestionDel(data).then(() => {
        // console.log(res);
        this.$message({
          message: "移除成功！",
          type: "success",
        });
        // 调用 ，更新列表
        // v.isPlan = 0;
        this.componentsKey = new Date().getTime();
      });
    },
    //添加到试题篮
    exampaperplanquestion(val, type) {
      // console.log(val);
      // if (val) {
      //   return;
      // }
      let data = {
        planId: this.planId,
        questionId: type ? val.newId : val.questionId,
      };
      if (type == 2) {
        data.questionId = val.id;
      }
      exampaperplanquestion(data).then(() => {
        // console.log(res);
        this.$message({
          message: "加入成功",
          type: "success",
        });
        // val.isPlan = 1;
        this.componentsKey = new Date().getTime();
      });
    },
    // 试题篮方法 end
    getExamListByTimeRange(pageData) {
      let data = {
        subjectId: pageData.subjectId,
        timeRange: pageData.timeRange,
        gradeId: pageData.gradeId,
        levelId: pageData.level,
        classNum: pageData.classNum,
        studentId: pageData.studentId,
        // examType: "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,99"
      };
      this.examList = [];
      // this.examPaperIds = [];
      getExamListByTimeRange(data).then((res) => {
        // console.log(res);
        this.examList = res.data.data;
        this.examList.map((index) => {
          let str = this.examTypeOptions.filter(
            (item) => index.type == item.value
          )[0].label;
          index.examName =
            index.examDate + " " + index.examName + "（" + str + "）";
        });
      });
    },
    // 导出单道题
    exportQuestion(item) {
      this.$message({
        message: "数据转换中，请稍后！",
        type: "success",
      });
      // this.btnloading = true;
      let data = JSON.parse(JSON.stringify(this.childObj));
      data.questionId = item.questionId;
      if (data.studentId) {
        data = Object.assign(data, this.page, {
          subjectId: this.subjectOptionsVal,
        });
      } else {
        data = Object.assign(data, this.page);
      }
      questionExport(data).then((res) => {
        if (res.data.data) {
          fileDownloadByUrl(res.data.data);
        }
      });
    },
    getTrainings(i) {
      if (!this.pageList[i].bsBox) {
        trainings(this.pageList[i].examPaperQuestionId).then((res) => {
          if (res.data.data) {
            this.pageList[i].bsBox = true;
            this.pageList[i].bsList = res.data.data;
          } else {
            this.$message({
              message: "未匹配变式题",
              type: "warning",
            });
          }
        });
      } else {
        this.pageList[i].bsBox = false;
      }
      this.pageList = JSON.parse(JSON.stringify(this.pageList));
    },
    showQuestion(examPaperId, questionId) {
      traininglist(examPaperId, questionId).then((res) => {
        this.bsQuestionList = res.data.data;
        this.dialogVisible = true;
      });
    },
    setTime() {
      this.page.current = 1;
      let data = JSON.parse(JSON.stringify(this.$route.query));
      data.timeRange = this.optionsTimeVal;
      delete data.knowledgeName;
      let pageData = Object.assign(this.page, data, this.listVal);
      // console.log(pageData.examPaperIds);

      if (pageData.examPaperIds) {
        pageData.examPaperIds = pageData.examPaperIds.join(",");
      }

      if (this.listVal.quesType == "") {
        delete pageData.quesType;
      }

      this.loading = true;
      this.getMsg(pageData);
      this.getList(pageData);
      this.getChart(pageData);
      this.getMaster(pageData);
      this.getExamListByTimeRange(pageData);
    },
    getMaster(data) {
      masterinfo(data)
        .then((res) => {
          // console.log(res);
          this.masterinfoData = res.data.data;
          if (this.masterinfoData.masteredList.length == 0) {
            this.activeName = "second";
          }
          // activeName
        })
        .catch(() => {
          this.loading = false;
        });
    },
    drawLine() {
      // console.log(this.$echarts);
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(
        document.getElementById("myChart"),
        null,
        { devicePixelRatio: 2.5 }
      );
      // 绘制图表
      myChart.setOption({
        title: {
          // text: "得分率：" + this.scoreRateAvg,
          // subtext: "得分率" + this.scoreRateAvg,
          color: "#dddddd",
          // left: "center"
        },
        xAxis: {
          type: "category",
          data: this.examDateListY,
        },
        yAxis: {
          type: "value",
          axisLabel: {
            formatter: "{value} %",
          },
          //  min:95,
          max: 100,
        },
        grid: {
          // top: '4%',
          left: "0%",
          right: "0%",
          bottom: "0%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },

        series: [
          {
            data: this.examDateListX,
            type: "line",
            // itemStyle: { normal: { label: { show: true } } }
          },
        ],
      });
      this.loading = false;
    },
    getChart(data) {
      paperlistinfo(data)
        .then((res) => {
          this.echartsData = res.data.data;
          this.examDateListY = [];
          this.examDateListX = [];
          this.scoreRateAvg =
            (this.echartsData.scoreRateAvg * 100).toFixed(2) + " %";
          this.echartsData.examPaperList.forEach((index) => {
            this.examDateListY.push(index.examDate);
            let num = (index.scoreRateAvg * 100).toFixed(2);
            this.examDateListX.push(num);
          });
          if (this.examDateListY.length == 0) {
            return;
          }
          this.$nextTick(() => {
            this.drawLine();
          });
        })
        .catch(() => {
          this.loading = false;
        });
    },
    clickList(i) {
      this.page.current = i;
      let pageData = Object.assign(
        this.page,
        JSON.parse(JSON.stringify(this.$route.query)),
        this.listVal
      );
      if (pageData.examPaperIds) {
        pageData.examPaperIds = pageData.examPaperIds.join(",");
      }

      if (this.listVal.quesType == "") {
        delete pageData.quesType;
      }
      delete pageData.knowledgeName;
      this.getList(pageData);
    },

    show(i) {
      if (this.pageList[i].show) {
        this.pageList[i].show = false;
        return;
      }
      if (
        this.pageList[i].analysis
        // this.pageList[i].ability
      ) {
        this.pageList[i].show = true;
        this.pageList = [...this.pageList];
        return;
      }
      //  else {
      //   this.pageList[i].show = true;
      // }
      answer(this.pageList[i].questionId).then((res) => {
        // console.log(res);
        if (res.data.data.id) {
          this.pageList[i].answer = res.data.data.answer;
          this.pageList[i].analyse = res.data.data.analyse;
          this.pageList[i].analysis = res.data.data.analysis;
          this.pageList[i].ability = res.data.data.ability;
          this.pageList[i].knowledgeNames = res.data.data.knowledgeNames;
          if (res.data.data.id != this.pageList[i].id) {
            this.pageList[i].newQuestion = res.data.data.question;
            // v.knowledgeNames = res.data.data.knowledgeNames;
            this.pageList[i].newId = res.data.data.id;
            this.pageList[i].newXuekeId = res.data.data.xuekeId;
          }
          this.pageList[i].show = true;
          this.pageList = [...this.pageList];
        } else {
          this.$message({
            message: "未找到同类题和解析",
            type: "warning",
          });
        }
      });
      this.pageList = JSON.parse(JSON.stringify(this.pageList));
    },
    getList(data) {
      // subjectId=1&timeRange=0&gradeId=132&current=1&size=12&knowledgeId=3913q
      // let data = {
      //   subjectId: 1,
      //   timeRange: 0,
      //   gradeId: 132,
      //   current: 1,
      //   size: 12,
      //   knowledgeId: "3913q"
      // };
      questionPage(data)
        .then((res) => {
          this.loading = false;
          if (res.data.data) {
            this.pageList = res.data.data.records;
            this.total = res.data.data.total;
            this.pageList.map((index) => {
              index.show = false;
              index.bsBox = false;
            });
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    linkTo() {
      this.$router.go(-1);
    },
    getMsg(data) {
      // let data = {
      //   subjectId: 1,
      //   timeRange: 0,
      //   gradeId: 132,
      //   current: 1,
      //   size: 12,
      //   knowledgeId: "3913q"
      // };
      questionInfo(data)
        .then((res) => {
          // console.log(res);
          this.userInfo = res.data.data;
          this.questionTypeList = res.data.data.questionTypeList;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
<style lang="scss">
.showKnowledge {
  .morelist {
    // overflow-x: scroll;
    width: 120px;

    input:-ms-input-placeholder {
      color: #606266 !important;
    }
    input::placeholder {
      color: #606266;
    }
    .el-select__tags {
      flex-wrap: unset;
      overflow: hidden;
      .el-tag {
        color: #606266;
      }
    }
  }
  .tabs-list {
    margin-bottom: 12px;
  }
  .tabs-list > .el-tabs__header .el-tabs__item.is-active {
    color: $primary-color;
  }
  .tabs-list > .el-tabs__header .el-tabs__item:not(.is-disabled):hover {
    color: $primary-color;
  }
  .wrong-box {
    .set-input {
      width: 87px;
      //  .el-input .el-select__caret
      input {
        background-color: #f5f5f5;
        line-height: 35px !important;
        height: 35px !important;
        text-align: right;
        border: none;
        color: $primary-color;
      }
      .el-icon-arrow-up:before {
        // .el-icon-arrow-up:before
        content: "";
      }
      // .el-input__icon:after {
      //   content: "";
      // }
      .el-input {
        .el-select__caret {
          line-height: 35px;
          height: 35px;
        }
      }
    }
  }
}
</style>
<style scoped lang="scss">
.showKnowledge {
  .model-box {
    .el-dialog__body {
      padding-top: 12px;
    }
  }
  .bsTitle {
    margin-top: 12px;
    color: $primary-color;
  }
  .know-box {
    width: 1220px;
    margin: 30px auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .wrong-box {
      border: 1px solid #dddddd;
      padding: 80px 24px 24px;
      position: relative;
      margin: 24px 0;
      border: 1px solid #dddddd;
      padding: 12px;
      width: 100%;
      .page-box {
        display: flex;
        justify-content: flex-end;
        margin-top: 12px;
        margin-bottom: 20px;
        .el-pagination {
          padding-right: 0px;
        }
      }
      .question-list {
        border: 1px solid #dddddd;
        width: 100%;
        margin-top: 20px;
        position: relative;
        .anaws-box {
          padding: 12px;
          margin: 12px 0;
          .anaws-box-title {
            color: $primary-color;
          }
        }

        .question-intro {
          display: flex;
          padding: 10px 30px;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          background-color: #f5f5f5;

          span {
            margin-left: 20px;
            color: $primary-color;
            cursor: pointer;
          }
        }
        .question-box {
          margin: 24px 0;
          padding: 12px;
        }
        .quertion-num {
          position: absolute;
          left: 0;
          top: 0;
          width: 24px;
          height: 24px;
          line-height: 24px;
          text-align: center;
          color: $primary-color;
          background-color: #f6f6f6;
        }
      }
      .right-box-top {
        display: flex;
        justify-content: space-between;
        background-color: #f6f6f6;
        width: 100%;

        .top-left {
          padding: 8px 16px;
        }
        .top-right {
          display: flex;
          .one-box {
            // &:first-child {
            color: $primary-color;
            // }
          }
          .right-list {
            padding: 8px 16px;
            border-left: 1px solid #dddddd;
            cursor: pointer;
            .el-icon--right {
              transform: rotateZ(180deg);
            }
            .el-icon-arrow-down:before {
              content: "";
              // ;
            }
          }
          .onset {
            padding: 0 16px;
          }
        }
      }
    }
    .know-box-right {
      width: 870px;
      .list-master {
        display: flex;
        flex-flow: wrap;
        align-items: center;
        div {
          margin-right: 12px;
          color: #999999;
        }
      }
      .title {
        color: $primary-color;
        font-size: 18px;
      }
      .know-box-top {
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
          color: #999999;
          span {
            color: black;
          }
        }
      }
    }
    .know-box-left {
      width: 200px;
      border: 1px solid $primary-color;
      border-radius: 4px;
      div {
        padding: 12px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #dddddd;
        &:last-child {
          border-bottom: 0;
        }
      }
      .title {
        justify-content: space-between;
        display: flex;
      }
    }
  }
  .showKnowledge-title {
    border-bottom: 1px solid #dddddd;
    padding: 12px 0;
    .el-dropdown-link {
      cursor: pointer;
    }
    .title-box {
      width: 1220px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title-size {
        font-size: 18px;
        font-weight: 400;
        .el-icon-back {
          cursor: pointer;
        }
      }
    }
  }
}
</style>
